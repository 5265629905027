import { Cancel, Article, Settings, Update, Check, PersonAdd, Download } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Skeleton, Accordion, AccordionSummary, Box, Typography, AccordionDetails, FormControlLabel, Switch, Grid, TextField, MenuItem, Button, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, useMediaQuery, useTheme, Card, CardActionArea, CardContent, DialogActions, List, ListItem, ListItemButton, Checkbox, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import useDatabase from "../hooks/useDatabase";
import { DBResult, isSuccess } from "../utils/database";
import { IDeviceGroup, IDevice, IDualMeet_DB, IDualMeetBout, DeviceBootOptions, Weapon, DeviceMachine, Division, IUser, UserFlag } from "../utils/types";
import GroupNameText from "./GroupNameText";
import { useAppSelector } from "../utils/store";
import useStorage from "../hooks/useStorage";
import { formatFileSize } from "../utils/helpers";

const HSBoutStrings = Array(27)
    .fill(0)
    .map((_, idx) => {
        const weapon = Math.floor(idx / 3) % 3;
        const round = Math.floor(idx / 9) + 1;
        const bout = (idx % 3) + 1;

        const weaponArr = ["Sabre", "Foil", "Epee"];

        return `${weaponArr[weapon]} Round ${round} Bout ${bout}`;
    });

const CollegeBoutStrings = Array(9).fill(0).map((_, idx) => `Bout ${idx + 1}`);


interface ChooseMeetProps {
    meets: Record<string, IDualMeet_DB>;
    chooseMeet: (meet: IDualMeet_DB) => void;
    close: () => void;
}

const ChooseMeet = ({ meets, chooseMeet, close }: ChooseMeetProps) => {
    const [filter, setFilter] = useState("");
    const [allMeets, setAllMeets] = useState(Object.values(meets));
    
    useEffect(() => {
        setAllMeets(Object.values(meets).toSorted((a, b) => b.startedAt - a.startedAt));
    }, [meets]);

    const meetOptions: IDualMeet_DB[] = filter ? Object.values(allMeets).filter(l => l.name.toLowerCase().includes(filter.toLowerCase())) : [];

    const errorText = !filter ? "Type something to begin searching..." : !meetOptions.length ? "No meets found..." : "";

    return (
        <Box>
            <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
                Choose meet
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "600px" }}>
                    <TextField
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        label="Meet name"
                        placeholder="Start typing to find a meet..."
                        sx={{ marginTop: "10px", marginBottom: "30px" }}
                    />
                    <Grid container spacing={2} sx={{ padding: "0 15px 15px 15px", maxHeight: "400px", overflowY: "auto" }}>
                        { meetOptions.map(l => <Grid key={`meetOption${l.id}`} item xs={6} md={4}>
                            <Card>
                                <CardActionArea onClick={ () => chooseMeet(l) }>
                                    <CardContent>
                                        <Typography>{ l.name }</Typography>
                                        <Typography variant="caption" sx={{ color: "#444" }}>{ new Date(l.startedAt).toLocaleDateString("en-US") }</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid> ) }
                    </Grid>
                    { errorText ? <Box sx={{ width: "100%", height: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ color: "#999", fontFamily: "Lexend Deca", fontSize: 22 }}>{errorText}</Typography>
                    </Box> : null }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Box>
    );
};

interface SetOwnersProps {
    device: IDevice;
    allUsers: Record<string, IUser>;
    close: () => void;
}

const SetOwners = ({ device, allUsers, close }: SetOwnersProps) => {
    const DB = useDatabase();
    const [filter, setFilter] = useState("");

    const toggleUser = (userId: string) => {
        DB.toggleUserOwnershipOfDevice(device, userId);
    };

    const sortedUsers = useMemo(() => Object.values(allUsers).toSorted((a, b) => a.lastName.localeCompare(b.lastName)), [allUsers]);
    const users = useMemo(() => filter ? sortedUsers.filter(l => `${l.firstName} ${l.lastName}`.toLowerCase().includes(filter.toLowerCase())) : [], [sortedUsers, filter]);
    const owners = Object.keys(device.owners || {});

    const errorText = !filter ? "Type something to begin searching..." : !users.length ? "No accounts found..." : "";

    return <Box>
        <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
            Set Owners
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Choose which accounts have access to manage <strong>{ device.name }</strong>.
            </DialogContentText>
            <TextField
                fullWidth
                value={filter}
                onChange={e => setFilter(e.target.value)}
                label="Account name"
                placeholder="Start typing to find a user..."
                sx={{ marginTop: 2 }}
            />
            { errorText ? <Box sx={{ width: "100%", height: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                 <Typography sx={{ color: "#999", fontFamily: "Lexend Deca", fontSize: 22 }}>{errorText}</Typography>
             </Box> : null }
            <List>
                { users.map(l => <ListItem key={`userInList${l.id}`} disablePadding>
                    <ListItemButton onClick={() => toggleUser(l.id)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={owners.includes(l.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": `checkbox-${l.id}` }}
                            />
                        </ListItemIcon>
                        <ListItemText id={`checkbox-${l.id}`} primary={`${l.firstName} ${l.lastName}`} />
                    </ListItemButton>
                </ListItem>) }
            </List>
        </DialogContent>
    </Box>
}

interface DeviceProps {
    id: string;
    groups: Record<string, IDeviceGroup>;
    firmwareVersions: string[];
    hsMeets: Record<string, IDualMeet_DB>;
    collegeMeets: Record<string, IDualMeet_DB>;
    /**
     * For admin purposes only
     */
    allUsers: Record<string, IUser>;
    startInvitingOwners: () => void;
}

const Device = ({ id, groups, firmwareVersions, hsMeets, collegeMeets, allUsers, startInvitingOwners }: DeviceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const userInfo = useAppSelector(s => s.user.userInfo);
    const admin = Boolean((userInfo?.flags || 0) & UserFlag.SuperAdmin);

    const storage = useStorage();
    const DB = useDatabase();
    const [data, setData] = useState<IDevice | null>(null);
    const [meet, setMeet] = useState<IDualMeet_DB | null>(null);
    const [bout, setBout] = useState<IDualMeetBout | null>(null);
    const [owners, setOwners] = useState<IUser[]>([]);

    const [selectedFirmwareOption, setSelectedFirmwareOption] = useState("0.0.1");
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

    const [logsPresent, setLogsPresent] = useState(false);
    const [logsSize, setLogsSize] = useState(0);
    const [logsDate, setLogsDate] = useState(new Date());

    const [choosingMeet, setChoosingMeet] = useState(false);
    const [choosingMeetDivision, setChoosingMeetDivision] = useState<Division>(Division.NCAA);
    const [choosingMeetID, setChoosingMeetID] = useState<string | null>(null);
    const meetOptions = choosingMeetDivision === Division.NCAA ? collegeMeets : hsMeets;

    const group = data?.group ? groups[data.group] : undefined;

    const [configuring, setConfiguring] = useState(false);
    const [configureID, setConfigureID] = useState("");
    const [configureName, setConfigureName] = useState("");
    
    const [settingOwners, setSettingOwners] = useState(false);

    const [snackbarError, setSnackbarError] = useState("");

    const handleDevice = (result: DBResult<IDevice>) => {
        if (result.status === "fail") return;
        const { data } = result;
        setData(data);
        setConfigureID(data.id);
        setConfigureName(data.name);
        const requestedUpdate = Boolean((data.bootOptions || 0) & DeviceBootOptions.UpdateFirmware);
        if (requestedUpdate) {
            setSelectedFirmwareOption(data.bootVersion || "0.0.1");
        } else {
            setSelectedFirmwareOption(data.installedVersion || "0.0.1");
        }
        const ownerIDs = Object.keys(data.owners || {});
        Promise.all(ownerIDs.map(l => DB.getUserInfo(l))).then(results => {
            const validResults = results.filter(isSuccess).map(l => l.data);
            setOwners(validResults);
        });
    }
    
    const updateLogsSize = async () => {
        const metadataResult = await storage.getFileMetadata(`auto-logs/${id}-logs.txt`);
        if (metadataResult.status === "fail") {
            return setLogsPresent(false);
        }

        setLogsPresent(true);
        setLogsSize(metadataResult.data.size);
        setLogsDate(new Date(metadataResult.data.updated));
    }

    useEffect(() => {
        DB.getDevice(id, handleDevice);
        updateLogsSize();

        return () => {
            DB.stopListeningDevice(id, handleDevice);
        };
    }, [id]);

    useEffect(() => {
        if (data?.meet) DB.getMeet(data.meet, data.division).then((result: DBResult<IDualMeet_DB>) => {
            if (result.status === "fail") return;
            setMeet(result.data);
        });
    }, [data?.meet]);

    useEffect(() => {
        if (data?.bout) DB.getBout(data.bout, data.division).then(result => {
            if (result.status === "fail") return;
            setBout(result.data);
        });
    }, [data?.bout]);

    if (!data) {
        return <Skeleton height={100} sx={{ margin: 0 }} />;
    }

    const weaponObj: Record<Weapon, number> = {
        Sabre: 0,
        Foil: 1,
        Epee: 2,
    };

    const weaponArr: Weapon[] = ["Sabre", "Foil", "Epee"];

    const hsBout =
        data.weapon && data.boutIdx !== undefined
            ? Math.floor(data.boutIdx / 3) * 9 +
            (data.boutIdx % 3) +
            weaponObj[data.weapon] * 3
            : -1;

    const collegeBout = (data.boutIdx ?? -1);

    const setName = (name: string) => {
        DB.setDeviceName(data.id, name);
    };

    const setMachine = (machine: DeviceMachine) => {
        DB.setDeviceMachine(data.id, machine);
    };

    const setDivision = (division: Division) => {
        DB.setDeviceDivision(data.id, division);
    };

    const setSingleWeaponMode = (value: boolean) => {
        DB.setDeviceSingleWeaponOrder(data.id, value);
    };

    const setWeapon = (weapon: Weapon | undefined) => {
        DB.setDeviceWeapon(data.id, weapon || null, true);
    }

    const setHsBout = async (idx: number) => {
        if (idx === -1) {
            DB.setDeviceBoutIdx(data.id, null, true);
            return;
        }
        const realIdx = idx - 1;

        const weapon = Math.floor(realIdx / 3) % 3;
        const bout = Math.floor(realIdx / 9) * 3 + (realIdx % 3);

        let status = await DB.setDeviceBoutIdx(data.id, bout, true);
        if (status.status === "fail") return setSnackbarError(status.data);
        status = await DB.setDeviceWeapon(data.id, weaponArr[weapon], true);
        if (status.status === "fail") return setSnackbarError(status.data);
    };

    const setCollegeBout = async (idx: number) => {
        if (idx === -1) {
            DB.setDeviceBoutIdx(data.id, null, true);
            return;
        }

        const realIdx = idx - 1;

        const status = await DB.setDeviceBoutIdx(data.id, realIdx, true);
        if (status.status === "fail") return setSnackbarError(status.data);
    }

    const chooseMeet = async (meet: IDualMeet_DB) => {
        if (!choosingMeetID) return;
        await DB.setDeviceMeet(choosingMeetID, meet.id, false);
        setChoosingMeet(false);
        setChoosingMeetID(null);
    }

    const toggleRequestCrashLogs = async () => {
        if (!data) return;

        if (requestedCrashLogs) {
            DB.cancelRequestDeviceUploadLogs(data.id);    
        } else {
            DB.requestDeviceUploadLogs(data.id);
        }
    }

    const cancelUpdatingFirmware = async () => {
        if (!data) return;
        
        DB.cancelRequestDeviceUpdateFirmware(data.id);
    }

    const confirmVersion = async () => {
        if (!data) return;

        if (selectedFirmwareOption === data.installedVersion) {
            DB.cancelRequestDeviceUpdateFirmware(data.id);
        } else {
            DB.requestDeviceUpdateFirmware(data.id, selectedFirmwareOption);
        }
    }

    const downloadLogs = async () => {
        const downloadUrlResult = await storage.getDownloadUrl(`auto-logs/${id}-logs.txt`);
        if (downloadUrlResult.status === "fail") return setSnackbarError(downloadUrlResult.data);

        const response = await fetch(downloadUrlResult.data)
        const text = await response.text();
        const newWindow = window.open()!;
        newWindow.document.write(`<pre>${text}</pre>`);
        newWindow.document.close();
    }

    const requestedCrashLogs = Boolean((data.bootOptions || 0) & DeviceBootOptions.UploadLogs);
    const requestedUpdate = Boolean((data.bootOptions || 0) & DeviceBootOptions.UpdateFirmware);
    const canConfirmVersion = selectedFirmwareOption !== (data.bootVersion || data.installedVersion);
    const ownersStr = owners.map(l => `${l.firstName} ${l.lastName}`).join(", ");

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${data.id}-content`}
                id={`panel${data.id}-header`}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1">{data.name}</Typography>
                    { group ? <GroupNameText sx={{ marginLeft: 1 }} groupColor={group.color}>{group.name}</GroupNameText> : null }
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={configuring}
                                onChange={(e) => setConfiguring(e.target.checked)}
                            />
                        }
                        label="Configure"
                    />
                    <Button
                        component={Link}
                        to={`/viewer/${id}`}
                        variant="contained"
                    >
                        View display
                    </Button>
                </Box>
                {configuring ? (
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Info</Typography>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginRight: "10px" }}>ID: {configureID}</Typography>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginRight: "10px" }}>Name: </Typography>
                                <TextField
                                    value={configureName}
                                    onChange={(e) => setConfigureName(e.target.value)}
                                    onBlur={(e) => setName(e.target.value)}
                                    hiddenLabel
                                    variant="standard"
                                />
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginRight: "10px" }}>Mode: </Typography>
                                <TextField
                                    value={data.machine}
                                    onChange={(e) => setMachine(e.target.value as DeviceMachine)}
                                    select
                                    hiddenLabel
                                    variant="standard"
                                >
                                    <MenuItem value={DeviceMachine.SG12}>SG-12</MenuItem>
                                    <MenuItem value={DeviceMachine.FA05}>FA-05</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Scoring</Typography>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginRight: "10px" }}>Division: </Typography>
                                <TextField
                                    value={data.division}
                                    onChange={(e) => setDivision(e.target.value as Division)}
                                    select
                                    hiddenLabel
                                    variant="standard"
                                >
                                    <MenuItem value={Division.NJSIAA}>High School</MenuItem>
                                    <MenuItem value={Division.NCAA}>College</MenuItem>
                                </TextField>
                            </Box>
                            { data.division === Division.NCAA ? 
                                <Tooltip title="Makes autoscorer cycle through all 3 weapons instead of only one" arrow>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={{ marginRight: "10px" }}>Three-weapon mode:</Typography>
                                        <Switch checked={Boolean(data.singleWeaponOrder)} onChange={e => setSingleWeaponMode(e.target.checked)} />
                                    </Box>
                                </Tooltip> : null
                            }
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography>Meet: {meet?.name || "Unset"}</Typography>
                                <Button
                                    variant="outlined"
                                    sx={{ marginLeft: "10px" }}
                                    onClick={() => { setChoosingMeetID(data.id); setChoosingMeetDivision(data.division); setChoosingMeet(true) }}
                                >
                                    Choose meet
                                </Button>
                            </Box>
                            {data.division === Division.NJSIAA ? (
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ marginRight: "10px" }}>Bout: </Typography>
                                    <TextField
                                        value={hsBout + 1}
                                        onChange={(e) => setHsBout(Number(e.target.value))}
                                        select
                                        hiddenLabel
                                        variant="standard"
                                        SelectProps={{
                                            MenuProps: {
                                                slotProps: { paper: { style: { maxHeight: 200 } } },
                                            },
                                        }}
                                    >
                                        <MenuItem value={-1}>Unset</MenuItem>
                                        {HSBoutStrings.map((l, idx) => (
                                            <MenuItem value={idx + 1} key={`boutSel${idx}`}>
                                                {l}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            ) : (
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={{ marginRight: "10px" }}>
                                            Weapon:{" "}
                                        </Typography>
                                        <TextField
                                            value={data.weapon || undefined}
                                            onChange={(e) => setWeapon(e.target.value as Weapon)}
                                            select
                                            hiddenLabel
                                            variant="standard"
                                        >
                                            <MenuItem value={undefined}>Unset</MenuItem>
                                            <MenuItem value="Sabre">Sabre</MenuItem>
                                            <MenuItem value="Foil">Foil</MenuItem>
                                            <MenuItem value="Epee">Epee</MenuItem>
                                        </TextField>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={{ marginRight: "10px" }}>Bout: </Typography>
                                        <TextField
                                            value={collegeBout + 1}
                                            onChange={(e) => setCollegeBout(Number(e.target.value))}
                                            select
                                            hiddenLabel
                                            variant="standard"
                                            SelectProps={{
                                                MenuProps: {
                                                    slotProps: { paper: { style: { maxHeight: 200 } } },
                                                },
                                            }}
                                        >
                                            <MenuItem value={0}>Unset</MenuItem>
                                            {CollegeBoutStrings.map((l, idx) => (
                                                <MenuItem value={idx + 1} key={`collegeBoutSel${idx}`}>
                                                    {l}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Firmware</Typography>
                            <Box sx={{ display: "flex" }}>
                                { requestedCrashLogs
                                    ? <Typography sx={{ flexShrink: 1 }}>The device is set to upload its internal logs the next time it boots.</Typography>
                                    : null
                                }
                                <Button
                                    sx={{ flexShrink: 0, flexGrow: 1 }}
                                    startIcon={requestedCrashLogs ? <Cancel /> : <Article />}
                                    onClick={() => toggleRequestCrashLogs()}
                                >
                                    { requestedCrashLogs ? "Cancel" : "Retrieve internal logs" }
                                </Button>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                { requestedUpdate
                                    ? <Typography sx={{ flexShrink: 1 }}>The device is set to update its firmware the next time it boots.</Typography>
                                    : null
                                }
                                <Button
                                    sx={{ flexShrink: 0, flexGrow: 1 }}
                                    startIcon={requestedUpdate ? <Settings /> : <Update />}
                                    onClick={() => setUpdateDialogOpen(true)}
                                >
                                    { requestedUpdate ? "Change" : "Update firmware version" }
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Ownership</Typography>
                            <Typography>Owners: { ownersStr || "None" }</Typography>
                            { admin
                                ? <Button startIcon={<PersonAdd />} onClick={() => setSettingOwners(true)}>Set managers</Button>
                                : <Button startIcon={<PersonAdd />} onClick={() => startInvitingOwners()}>Add managers</Button>
                            }
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Info</Typography>
                            <Typography sx={{ marginRight: "10px" }}>ID: {configureID}</Typography>
                            <Typography sx={{ marginRight: "10px" }}>Name: { data.name }</Typography>
                            <Typography sx={{ marginRight: "10px" }}>Mode: { data.machine }</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Scoring</Typography>
                            <Typography sx={{ marginRight: "10px" }}>Division: { data.division }</Typography>
                            <Typography>Meet: {meet?.name || "Unset"}</Typography>
                            {data.division === Division.NJSIAA ? (
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ marginRight: "10px" }}>Bout: { hsBout === -1 ? "Unset" : HSBoutStrings[hsBout] }</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={{ marginRight: "10px" }}>
                                            Bout: { data.weapon || "Unset" } { collegeBout === -1 ? "Unset" : CollegeBoutStrings[collegeBout] }
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Firmware</Typography>
                            { logsPresent
                                ? <Typography>
                                    Logs uploaded at { logsDate.toLocaleDateString() } { logsDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) }
                                </Typography>
                                : <Typography>No logs uploaded.</Typography>
                            }
                            { logsPresent && <Button startIcon={<Download />} href={`/logs/${id}`}>
                                Download logs ({formatFileSize(logsSize)})
                            </Button> }
                            <Typography>Installed firmware version: { data.installedVersion || "0.0.1" }</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ paddingBottom: 1 }}>Ownership</Typography>
                            <Typography>Owners: { ownersStr || "None" }</Typography>
                        </Grid>
                    </Grid>
                )}
                { snackbarError ? <Alert severity="error">
                    { snackbarError }
                </Alert> : null }
            </AccordionDetails>
            <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
                <DialogTitle>Update Device Firmware</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a new firmware version for <span style={{ fontWeight: "bold" }}>{ data.name }</span>.
                        Note that the device will not download any changes until it is rebooted.
                    </DialogContentText>
                    <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                        <Box sx={{ marginRight: 1 }}>
                            <Typography>Installed version: <span style={{ fontWeight: "bold" }}>v{ data.installedVersion || "None" }</span></Typography>
                            { requestedUpdate && <Typography>Requested version: <span style={{ fontWeight: "bold" }}>v{ data.bootVersion || "None" }</span></Typography> }
                        </Box>
                        <FormControl>
                            <InputLabel id="version-select-label">Version</InputLabel>
                            <Select
                                labelId="version-select-label"
                                id="version-select"
                                label="Version"
                                value={ selectedFirmwareOption }
                                onChange={ e => setSelectedFirmwareOption(e.target.value) }
                                >
                                { firmwareVersions.map(l => <MenuItem value={l} key={`versionOption${l}`}>v{ l }</MenuItem>) }
                            </Select>
                        </FormControl>
                        <Button
                            disabled={!canConfirmVersion}
                            startIcon={<Check />}
                            sx={{ marginLeft: 1 }}
                            onClick={() => confirmVersion()}
                        >
                            Set version
                        </Button>
                        <Button
                            disabled={!requestedUpdate}
                            color="error"
                            startIcon={<Cancel />}
                            sx={{ marginLeft: 1 }}
                            onClick={() => cancelUpdatingFirmware()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={false}
                open={Boolean(choosingMeet)}
                onClose={() => setChoosingMeet(false)}
            >
                <ChooseMeet
                    meets={meetOptions}
                    chooseMeet={chooseMeet}
                    close={() => setChoosingMeet(false)}
                />
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={false}
                open={settingOwners}
                onClose={() => setSettingOwners(false)}
            >
                <SetOwners
                    device={data}
                    allUsers={allUsers}
                    close={() => setSettingOwners(false)}
                />
            </Dialog>
        </Accordion>
    );
};

export default Device;
export enum Division {
    NJSIAA = "High School",
    NCAA = "College"
}
export enum SchoolType {
    College = "College",
    HS = "High School",
    MS = "Middle School"
}
export enum CollegeProgramType {
    Varsity = "Varsity",
    Club = "Club",
    JV = "JV",
    None = "None"
}
export type Weapon = "Sabre" | "Epee" | "Foil";
export type Strip = "A" | "B" | "C";
export type Gender = "boys" | "girls";
export type ID = string;
export type DateTime = number;
export enum BoutSide {
    Left = 1,
    Right = 2
}

export type Listener<T> = (val: T) => void;

export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export interface RosterCSVFencer {
    firstName: string;
    lastName: string;
    gradYear?: number;
    weapon: Weapon;
    homeStrip: number;
    awayStrip: number;
}

export type RosterCSV = RosterCSVFencer[];

export interface IFencerBase {
    firstName: string;
    lastName: string;
}

export interface IExistingFencer extends IFencerBase {
    // Foreign key to IUser
    id: ID;
    createdBy: ID;
    createdAt: DateTime;
    updatedAt: DateTime;
    pin: number;
    gradYear?: number;
    // Array of foreign keys to ITeam
    teams: ID[];
    // Array of foreign keys to IBout
    bouts: ID[];
}

export interface IWriteInFencer extends IFencerBase {
    teamName: string;
    teamAbbreviation?: string;
}

export interface ITeam {
    id: ID;
    abbreviation?: string;
    // Array of foreign keys to IUser
    administrators: ID[];
    // Array of foreign keys to IUser
    managers: ID[];
    // TODO: find a better way to do this maybe
    boysAndGirlsTeam: "boys" | "girls" | "both";
    countryCode: string;
    region: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    // Record of seasons to dual meet IDs
    dualMeets: Record<string, ID[]>;
    // All fencers that are part of the team, regardless if they're on the roster or not
    fencers: ID[];
    orgID?: ID;
    publishedAt?: DateTime;
    publishedBy?: ID;
    published: boolean;
    name: string;
    pin: number;
    // Record of seasons to record of weapons to records of fencer ids
    roster: Record<
        string,
        Record<
            Weapon,
            // Record of foreign keys to IFencer to their role
            Record<ID, { home: number; away: number; power?: number }>
        >
    >;
    seasons: string[];
    type: SchoolType;
    writeIn?: boolean;
    conference?: string;
}

export interface IOrganization {
    id: string;
    name: string;
    abbreviation?: string;
    administrators: ID[];
    boysTeam?: ID;
    girlsTeam?: ID;
    boysJVTeam?: ID;
    girlsJVTeam?: ID;
    countryCode: string;
    // For some reason, division is used here in college
    region: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    publishedAt?: DateTime;
    publishedBy?: ID;
    published: boolean;
    type: SchoolType;
    writeIn?: boolean;
    // District (i.e. 1-6) for HS, region (South, Midwest, etc.) for college
    district?: string;
}

export enum UserFlag {
    // Verified account
    Verified = 1 << 0,
    // Disabled user
    Disabled = 1 << 1,
    // Can verify accounts/organizations
    SuperAdmin = 1 << 2,
    // Can manage other teams
    UberAdmin = 1 << 3,
    // Can manage tournaments (formerly matches)
    BoutCommittee = 1 << 4,
    // Can manage dual meets
    MeetManager = 1 << 5
}

export interface IUser {
    id: ID;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    // Only for use with migrated accounts (accounts that existed prior to v2)
    migratedAt?: DateTime;
    verifiedAt?: DateTime;
    verificationPin: number;
    // Bit flags for different roles/permissions
    // Refer to enum UserFlag to use
    flags: number;
    // Array of foreign keys to ITeam
    teams: ID[];
    // Array of foreign keys to ITeam
    managingTeams: ID[];
    // Links the user to a fencer
    linkedFencerIds: ID[];
}

export enum BoutEventType {
    End = "End",
    Card = "Card",
    Start = "Start",
    Touch = "Touch",
    Timeout = "Timeout",
    Forfeit = "Forfeit",
    Priority = "Priority",
    ClockEnd = "ClockEnd",
    TimeoutEnd = "TimeoutEnd",
    AnnulTouch = "AnnulTouch",
    DoubleTouch = "DoubleTouch",
    SwitchSides = "SwitchSides",
    TimeoutCancel = "TimeoutCancel",
    MedicalForfeit = "MedicalForfeit",
    RescindForfeit = "RescindForfeit",
    RandomPriority = "RandomPriority",
    PriorityRemoved = "PriorityRemoved"
}

export interface ICardInfo {
    yellow: boolean;
    red: number;
    black: boolean;
}

export interface IBoutEvent {
    id: ID;
    description: string;
    // Maybe consider making IBoutEventCards into bit flags (number)
    fencer1Cards: ICardInfo;
    fencer2Cards: ICardInfo;
    score1: number;
    score2: number;
    boutTime: number;
    localTime: DateTime;
    type: BoutEventType;
}

export enum BoutType {
    DualMeet = "dualMeet",
    Quick = "quick"
}

export interface IBoutCore {
    id: ID;
    color: string;
    currentSpectatorsNum: number;
    // Foreign key to IBoutEvent
    log: ID[];
    order: number;
    pin: number;
    weapon: Weapon;
    type: BoutType;

    fencer1: IDualMeetBoutFencer | IQuickBoutFencer;
    fencer2: IDualMeetBoutFencer | IQuickBoutFencer;

    switchedSides: boolean;
    priority: "left" | "right" | null;

    currentlyEditingUser?: ID;

    boutTime: number;
    startedAt?: DateTime;
    endedAt?: DateTime;
    updatedAt?: DateTime;
}

export type IDualMeetBoutFencerInfo = IWriteInFencer & {
    id?: string;
    gradYear?: number;
};

export interface IDualMeetBoutFencer {
    fencerInfo: IDualMeetBoutFencerInfo;
    cardInfo: ICardInfo;
    score: number;
    timeout: boolean;
    forfeit: boolean;
    strip: number;
    medicalFencerInfo?: IDualMeetBoutFencerInfo;
    medicalForfeit?: boolean;
}

export interface IQuickBoutFencer {
    fencerInfo: IWriteInFencer;
    cardInfo: ICardInfo;
    score: number;
    forfeit: boolean;
    timeout: boolean;
    medicalFencerInfo?: IDualMeetBoutFencerInfo;
    medicalForfeit?: boolean;
}

export interface IDualMeetBout extends IBoutCore {
    fencer1: IDualMeetBoutFencer;
    fencer2: IDualMeetBoutFencer;

    // Foreign key to IDualMeet
    dualMeetId: ID;
    type: BoutType.DualMeet;
}

export interface IQuickBout extends IBoutCore {
    // Pretty sure these are name only, no IDs
    fencer1: IQuickBoutFencer;
    fencer2: IQuickBoutFencer;
    type: BoutType.Quick;
}

export enum DualMeetType {
    Varsity,
    Club,
    MiddleSchool,
    JV,
    CollegeEvent
}

export interface IDualMeetTeam {
    id?: ID;
    name: string;
    administrators: string[];
    managers: string[];
    abbreviation: string;
    fencers: Record<Weapon, (IWriteInFencer & { home: number; away: number })[]>;
}

export type LineupFencer = IDualMeetBoutFencerInfo | { type: "forfeit" };

export type Lineup = Record<Weapon, Partial<Record<1 | 2 | 3 | 4 | 5 | 6, LineupFencer>>>;

export type DualMeetSignatureKey =
    | "sabreRef"
    | "foilRef"
    | "epeeRef"
    | "team1Sabre"
    | "team1Foil"
    | "team1Epee"
    | "team2Sabre"
    | "team2Foil"
    | "team2Epee"
    | "team1"
    | "team2";

export interface IDualMeet_DB {
    id: ID;
    eventID?: ID;
    type: DualMeetType;
    // Each team needs their own PIN
    pin1: number;
    pin2: number;
    refereePin?: number;
    name: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    published: boolean;
    // Foreign key to IUser
    publishedBy?: ID;
    publishedAt?: DateTime;
    season: string;
    startedAt: DateTime;
    endedAt?: DateTime;
    // Foreign key to ITeam
    team1ID?: ID;
    // Foreign key to ITeam
    team2ID?: ID;
    team1WriteInData?: IDualMeetTeam;
    team2WriteInData?: IDualMeetTeam;
    bouts: { id: ID; winner: BoutSide | 0 }[];
    // Foreign key to IDualMeet
    correspondingMeet?: ID;
    // Unused
    lineupsVisible?: boolean;
    lineupsVisibleSabre?: boolean;
    lineupsVisibleFoil?: boolean;
    lineupsVisibleEpee?: boolean;
    team1Lineup?: Lineup;
    team2Lineup?: Lineup;
    sabreReferee?: string;
    foilReferee?: string;
    epeeReferee?: string;
    singleReferee?: boolean;
    signatures?: Partial<Record<DualMeetSignatureKey, string>>;
}

export interface IDualMeet {
    id: ID;
    eventID?: ID;
    type: DualMeetType;
    // Each team needs their own PIN
    pin1: number;
    pin2: number;
    refereePin?: number;
    name: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    published: boolean;
    // Foreign key to IUser
    publishedBy?: ID;
    publishedAt?: DateTime;
    season: string;
    startedAt: DateTime;
    endedAt?: DateTime;
    // Foreign key to ITeam
    team1: IDualMeetTeam;
    // Foreign key to ITeam
    team2: IDualMeetTeam;
    bouts: { id: ID; winner: BoutSide | 0 }[];
    // Foreign key to IDualMeet
    correspondingMeet?: ID;
    lineupsVisible?: boolean;
    lineupsVisibleSabre?: boolean;
    lineupsVisibleFoil?: boolean;
    lineupsVisibleEpee?: boolean;
    team1Lineup?: Lineup;
    team2Lineup?: Lineup;
    sabreReferee?: string;
    foilReferee?: string;
    epeeReferee?: string;
    singleReferee?: boolean;
    signatures?: Partial<Record<DualMeetSignatureKey, string>>;
}

export interface IPublicationApplication {
    id: ID;
    orgId: ID;
    // This is here just for convenience
    orgName: string;
    userId: ID;
    name: string;
    school: string;
    additionalInfo?: string | null;
    submittedAt: DateTime;
    takeover: boolean;
}

export interface ICollegeEventRoundMeet {
    id: ID;
    nameA: string;
    nameB: string;
    idA: string;
    idB: string;
    abbA: string;
    abbB: string;
    regionA: string;
    regionB: string;
}

export interface ICollegeEventRound {
    name: string;
    meets: ICollegeEventRoundMeet[];
    byes: { name: string; abb: string; region: string; id: ID }[];
}

export interface ICollegeEventTeam {
    name: string;
    id: string;
    abbreviation: string;
    orgID?: string;
    type: CollegeProgramType;
}

export interface ICollegeEvent {
    id: ID;
    season: string;
    published: boolean;
    name: string;
    location: string;
    hostName: string;
    address: string;
    createdBy: ID;
    createdAt: number;
    startedAt: number;
    refereePin: number;
    mensRounds: ICollegeEventRound[];
    womensRounds: ICollegeEventRound[];
    mensTeams: ICollegeEventTeam[];
    womensTeams: ICollegeEventTeam[];
}

export type CreateFencerAction = {
    type: "createFencer";
    id: string;
    firstName: string;
    lastName: string;
    graduationYear?: number;
    season: string;
    weapon: Weapon;
};

export type Action =
    | { type: "addFencer"; id: string; season: string; weapon: Weapon }
    | CreateFencerAction
    | { type: "deleteFencer"; id: string; season: string; weapon: Weapon }
    | {
          type: "editFencer";
          id: string;
          firstName: string;
          lastName: string;
          graduationYear?: number;
          season: string;
          weapon: Weapon;
          power?: number;
      }
    | {
          type: "updateStrip";
          id: string;
          season: string;
          weapon: Weapon;
          home: boolean;
          strip: number;
      }
    | { type: "cloneSeason"; seasonToClone: string; newSeason: string };

export type ActionQueue = Action[];

export type RosterRecords = Record<Weapon, Record<string, { wins: number; losses: number }>>;

export type BoutScorerTeamRoster = Record<string, (IExistingFencer | IWriteInFencer)[]>;

export interface ITeamInvite {
    id: ID;
    type: "team";
    team: ID;
    code: string;
    createdBy: ID;
    createdAt: DateTime;
    claimed: boolean;
    claimedAt?: DateTime;
    expiresAt: DateTime;
}

export interface IFencerRecord {
    id: string;
    bouts: Record<ID, { won: boolean; lost: boolean; weapon: Weapon; season: string }>;
}

export enum DeviceMachine {
    SG12 = "SG12",
    FA05 = "FA05"
}

export enum DeviceBootOptions {
    UploadLogs = 1 << 0,
    UpdateFirmware = 1 << 1
}

export interface IDevice {
    id: string;
    name: string;
    event?: string;
    eventGender?: "mens" | "womens";
    eventStrip?: number;
    roundIdx?: number;
    meetIdx?: number;
    boutIdx?: number;
    weapon?: Weapon;
    meet?: string;
    bout?: string;
    division: Division;
    group?: ID;
    machine: DeviceMachine;
    singleWeaponOrder?: number;

    owners?: Record<ID, boolean>;

    installedVersion?: string;
    bootVersion?: string;
    bootOptions?: number;
}

export interface IDeviceGroup {
    id: ID;
    createdBy: ID;
    createdAt: DateTime;
    owner: ID;
    name: string;
    color: string;
    devices: Record<ID, boolean>;
    event?: string;
    eventRound: number;
}

export interface IDeviceInvite {
    id: ID;
    type: "device";
    devices: Record<ID, boolean>;
    code: string;
    createdBy: ID;
    createdAt: DateTime;
    claimed: boolean;
    claimedAt?: DateTime;
    expiresAt: DateTime;
}
import { exec } from "child_process";
import { userInfo } from "os";
import * as fs from "fs/promises";
import { Auth } from "firebase/auth";
import { Database, Unsubscribe, equalTo, get, onValue, orderByChild, push, query, ref, remove, set, update } from "firebase/database";
import { IDevice, IDualMeetBout, Listener, Weapon, IDualMeet_DB, Division, IUser, IDeviceGroup, ICollegeEvent, DeviceMachine, DeviceBootOptions, UserFlag, IDeviceInvite, ID } from "./types";
import COMMON_DB from "./commonDatabase";
import { genRandomColor, genRandomStr, idxFromWeaponHs } from "./helpers";
import { ERROR_BOUT_DOES_NOT_EXIST, ERROR_DEVICE_DOES_NOT_EXIST, ERROR_DEVICE_GROUP_DOES_NOT_EXIST, ERROR_DUAL_MEET_DOES_NOT_EXIST, ERROR_EVENT_DOES_NOT_EXIST, ERROR_INVALID_INVITE, ERROR_INVITE_CLAIMED, ERROR_NOT_LOGGED_IN, ERROR_UNKNOWN } from "./constants";

export class DBSuccess<T> {
    public readonly status = "success";
    constructor(public data: T) {}
}
export class DBError<R> {
    public readonly status = "fail";
    constructor(public data: R) {}
}
export type DBResult<T = void, R = string> = DBSuccess<T> | DBError<R>;
// We need this to prevent TS from whining
export const AsyncDBResult = Promise;
export type AsyncDBResult<T = void, R = string> = Promise<DBResult<T, R>>;

export const isSuccess = <T = void, R = string>(result: DBResult<T, R>): result is DBSuccess<T> => {
    return result.status === "success";
};
export const isFailure = <T = void, R = string>(result: DBResult<T, R>): result is DBError<R> => {
    return result.status === "fail";
};

export default class DB {
    public PREFIX = "/testAuto";
    public HS_PREFIX = "/test";
    public COLLEGE_PREFIX = "/test";
    protected database: Database;
    protected auth: Auth;

    private unsubscribers: WeakMap<Listener<unknown>, Unsubscribe> = new WeakMap();
    private deviceUnsubscribers: WeakMap<Listener<unknown>, Record<string, Unsubscribe>> = new WeakMap();

    constructor(prefix: string = "/testAuto", hsPrefix = "/test", collegePrefix = "/test", db: Database, auth: Auth) {
        this.PREFIX = prefix;
        this.HS_PREFIX = hsPrefix;
        this.COLLEGE_PREFIX = collegePrefix;
        this.database = db;
        this.auth = auth;
    }

    private divToPrefix = (division: Division) => division === Division.NJSIAA ? this.HS_PREFIX : this.COLLEGE_PREFIX;

    public getCurrentUserInfo(listener?: Listener<DBResult<IUser>>): AsyncDBResult<IUser> {
        return COMMON_DB.getCurrentUserInfo(listener);
    }

    public getUserInfo(userId: string): AsyncDBResult<IUser> {
        return COMMON_DB.getUserInfo(userId);
    }

    public getUserList(): AsyncDBResult<Record<string, IUser>> {
        return COMMON_DB.getUserList();
    }

    public async getDevices(listener?: Listener<DBResult<Record<string, IDevice>>>): AsyncDBResult<Record<string, IDevice>> {
        if (listener) {
            return new Promise((res) => {
                const l = v => {
                    const val: Record<string, IDevice> = v.val() || {};
                    const response = new DBSuccess(val);
                    listener(response);
                    res(response);
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/devices`), l);
                this.unsubscribers.set(listener, unsubscriber);
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/devices`));
            const val = v.val() || {};
            return new DBSuccess(val);
        }
    }

    public async getDevicesForUser(user: string | IUser, options?: { listener?: Listener<DBResult<Record<string, IDevice>>> }): AsyncDBResult<Record<string, IDevice>> {
        const listener = options?.listener;
        
        let userData = user;
        if (typeof userData === "string") {
            const userResult = await this.getUserInfo(userData);
            if (userResult.status === "fail") return userResult;
            userData = userResult.data;
        }
        const userId = userData.id;
        const admin = Boolean(userData.flags & UserFlag.SuperAdmin);
        
        if (listener) {
            return new Promise((res) => {
                const l = v => {
                    const val: Record<string, IDevice> = v.val() || {};
                    const userDevices: Record<string, IDevice> = {};
                    for (const id in val) {
                        if (admin || userId in (val[id].owners || {})) userDevices[id] = val[id];
                    }
                    const response = new DBSuccess(userDevices);
                    listener(response);
                    res(response);
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/devices`), l);
                this.unsubscribers.set(listener, unsubscriber);
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/devices`));
            const val: Record<string, IDevice> = v.val() || {};
            const userDevices: Record<string, IDevice> = {};
            for (const id in val) {
                if (admin || userId in (val[id].owners || {})) userDevices[id] = val[id];
            }
            return new DBSuccess(userDevices);
        }
    }

    public stopListeningDevices = (listener: Listener<DBResult<Record<string, IDevice>>>) => {
        const l = this.unsubscribers.get(listener);
        if (l) {
            l();
            this.unsubscribers.delete(listener);
        }
    }

    public async getDevice(id: string, listener?: Listener<DBResult<IDevice>>): AsyncDBResult<IDevice> {
        if (listener) {
            return new Promise((res, rej) => {
                const l = v => {
                    const val: IDevice | null = v.val();
                    if (!val) return res(new DBError(ERROR_DEVICE_DOES_NOT_EXIST))
                    listener(new DBSuccess(val));
                    res(new DBSuccess(val));
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/devices/${id}`), l);
                if (this.deviceUnsubscribers.has(listener)) {
                    this.deviceUnsubscribers.get(listener)![id] = unsubscriber;
                } else {
                    this.deviceUnsubscribers.set(listener, { [id]: unsubscriber });
                }
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/devices/${id}`));
            const val = v.val();
            if (!val) return new DBError(ERROR_DEVICE_DOES_NOT_EXIST);
            return new DBSuccess(val);
        }
    }

    public stopListeningDevice = (id: string, listener: Listener<DBResult<IDevice>>) => {
        const l = this.deviceUnsubscribers.get(listener);
        if (l) {
            l[id]();
            delete l[id];
        }
    }

    // Configure device

    public async setDeviceID(deviceId: string, newId: string): AsyncDBResult<boolean> {
        if (deviceId === newId) return new DBSuccess(true);
        const device = await this.getDevice(deviceId);
        if (device.status === "fail") return device;
        device.data.id = newId;
        await set(ref(this.database, `${this.PREFIX}/devices/${newId}`), device.data);
        await remove(ref(this.database, `${this.PREFIX}/devices/${deviceId}`));
        return new DBSuccess(true);
    }

    public async setDeviceName(deviceId: string, name: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/name`), name);
        return new DBSuccess(true);
    }

    public async setDeviceMachine(deviceId: string, machine: DeviceMachine): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/machine`), machine);
        return new DBSuccess(true);
    }

    public async setDeviceDivision(deviceId: string, division: Division): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/division`), division);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/event`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/weapon`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/roundIdx`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/boutIdx`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/meetIdx`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/meet`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/bout`), null);
        return new DBSuccess(true);
    }

    public async setDeviceSingleWeaponOrder(deviceId: string, value: boolean): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/singleWeaponOrder`), Number(value));
        return new DBSuccess(true);
    }

    public async setDeviceEvent(deviceId: string, eventId: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/event`), eventId);
        return new DBSuccess(true);
    }

    public async setDeviceEventGender(deviceId: string, gender: "mens" | "womens" | null): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/eventGender`), gender);
        return new DBSuccess(true); 
    }

    public async setDeviceEventStrip(deviceId: string, strip: number | null): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/eventStrip`), strip);
        return new DBSuccess(true); 
    }

    public async setDeviceWeapon(deviceId: string, weapon: Weapon | null, propagate: boolean): AsyncDBResult<boolean> {
        const deviceResult = await this.getDevice(deviceId);
        if (deviceResult.status === "fail") return deviceResult;
        const device = deviceResult.data;
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/weapon`), weapon);
        if (propagate) {
            if (weapon === null) {
                await this.setDeviceBout(deviceId, null, false);
            } else if (device.meet && typeof device.boutIdx === "number") {
                const meetResult = await this.getMeet(device.meet, device.division);
                if (meetResult.status === "fail") return meetResult;
                const meet = meetResult.data;
                const weaponObj = { Sabre: 0, Foil: 1, Epee: 2 };
                const bout = meet.bouts[(weaponObj[weapon] * 3) + (Math.floor(device.boutIdx / 3) * 9) + (device.boutIdx % 3)].id;
                if (device.bout !== bout) {
                    await this.setDeviceBout(deviceId, bout, false);
                }
            }
        }
        return new DBSuccess(true);
    }

    public async setDeviceMeet(deviceId: string, meetId: string, propagate: boolean): AsyncDBResult<boolean> {
        const deviceResult = await this.getDevice(deviceId);
        if (deviceResult.status === "fail") return deviceResult;
        const device = deviceResult.data;
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/meet`), meetId);
        
        if (propagate && device.weapon && device.boutIdx !== undefined) {
            const meetResult = await this.getMeet(meetId, device.division);
            if (meetResult.status === "fail") return meetResult;
            const meet = meetResult.data;
            const boutIdx = idxFromWeaponHs(device.weapon, device.boutIdx);
            const boutId = meet.bouts[boutIdx].id;
            await this.setDeviceBout(deviceId, boutId, false);
        }
        return new DBSuccess(true);
    }

    public async setDeviceBoutIdx(deviceId: string, index: number | null, propagate: boolean): AsyncDBResult<boolean> {
        const deviceResult = await this.getDevice(deviceId);
        if (deviceResult.status === "fail") return deviceResult;
        const device = deviceResult.data;
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/boutIdx`), index);
        if (propagate) {
            if (index === null) {
                await this.setDeviceBout(deviceId, null, false);
            } else if (device.meet && device.weapon) {
                const meetResult = await this.getMeet(device.meet, device.division);
                if (meetResult.status === "fail") return meetResult;
                const meet = meetResult.data;
                const idx = idxFromWeaponHs(device.weapon, index);
                const bout = meet.bouts[idx].id;
                if (device.bout !== bout) {
                    await this.setDeviceBout(deviceId, bout, false);
                }
            }
        }
        return new DBSuccess(true);
    }

    public async setDeviceBout(deviceId: string, boutId: string | null, propagate: boolean): AsyncDBResult<boolean> {
        const deviceResult = await this.getDevice(deviceId);
        if (deviceResult.status === "fail") return deviceResult;
        const device = deviceResult.data;
        if (boutId === null) {
            await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/bout`), boutId);
        } else {
            const boutResult = await this.getBout(boutId, device.division);
            if (boutResult.status === "fail") return boutResult;
            const bout = boutResult.data;
            await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/bout`), boutId);
            if (propagate && device.meet !== bout.dualMeetId || device.boutIdx !== bout.order || device.weapon !== bout.weapon) {
                this.setDeviceMeet(deviceId, bout.dualMeetId, false);
                this.setDeviceBoutIdx(deviceId, bout.order, false);
                this.setDeviceWeapon(deviceId, bout.weapon, false);
            }
        }
        return new DBSuccess(true);
    }

    public async toggleUserOwnershipOfDevice(deviceId: string | IDevice, userId: string): AsyncDBResult<boolean> {
        let device = deviceId;
        if (typeof device === "string") {
            const deviceResult = await this.getDevice(device);
            if (deviceResult.status === "fail") return deviceResult;
            device = deviceResult.data;
        }
        if (userId in (device.owners || {})) {
            await set(ref(this.database, `${this.PREFIX}/devices/${device.id}/owners/${userId}`), null);
        } else {
            await set(ref(this.database, `${this.PREFIX}/devices/${device.id}/owners/${userId}`), true);
        }
        return new DBSuccess(true);
    }

    // #region Device groups

    public async getDeviceGroup(id: string): AsyncDBResult<IDeviceGroup> {
        const v = await get(ref(this.database, `${this.PREFIX}/deviceGroups/${id}`));
        const val = v.val();
        if (!val) return new DBError(ERROR_DEVICE_GROUP_DOES_NOT_EXIST);
        if (!val.devices) val.devices = {};
        return new DBSuccess(val);
    }

    public async getDeviceGroups(options?: { listener?: Listener<DBResult<Record<string, IDeviceGroup>>> }): AsyncDBResult<Record<string, IDeviceGroup>> {
        const listener = options?.listener;
        if (listener) {
            return new Promise(res => {
                const l = v => {
                    const val: Record<string, IDeviceGroup> = v.val() || {};
                    for (const i in val) {
                        if (!val[i].devices) val[i].devices = {};
                    }
                    listener(new DBSuccess(val));
                    res(new DBSuccess(val));
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/deviceGroups`), l);
                this.unsubscribers.set(listener, unsubscriber);
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/deviceGroups`));
            const val = v.val() || {};
            for (const i in val) {
                if (!val[i].devices) val[i].devices = {};
            }
            return new DBSuccess(val);
        }
    }

    public async getDeviceGroupsForUser(userId: string, options?: { listener?: Listener<DBResult<Record<string, IDeviceGroup>>> }): AsyncDBResult<Record<string, IDeviceGroup>> {
        const filterGroups = (groups: Record<string, IDeviceGroup>) => {
            const res: Record<string, IDeviceGroup> = {};
            for (const id in groups) {
                if (groups[id].owner === userId) {
                    res[id] = groups[id];
                }
            }
            return res;
        }

        const listener = options?.listener;
        if (listener) {
            return new Promise(res => {
                const l = (v: DBResult<Record<string, IDeviceGroup>>) => {
                    const response = v.status === "success" ? new DBSuccess(filterGroups(v.data)) : v;
                    listener(response);
                    res(response);
                };
                this.getDeviceGroups({ listener: l });
            });
        } else {
            const groups = await this.getDeviceGroups();
            if (groups.status === "fail") return groups;
            return new DBSuccess(filterGroups(groups.data));
        }
    }

    public stopListeningDeviceGroups(listener: Listener<DBResult<Record<string, IDeviceGroup>>>) {
        const unsubscriber = this.unsubscribers.get(listener);
        if (unsubscriber) unsubscriber();
        return new DBSuccess(true);
    }

    public async createDeviceGroup(name: string, color: string): AsyncDBResult<boolean> {
        if (!this.auth.currentUser?.uid) return new DBError(ERROR_NOT_LOGGED_IN);
        const key = push(ref(this.database, `${this.PREFIX}/deviceGroups`)).key;
        if (!key) return new DBError(ERROR_UNKNOWN);
        const group: IDeviceGroup = {
            id: key,
            color,
            createdAt: Date.now(),
            createdBy: this.auth.currentUser.uid,
            owner: this.auth.currentUser.uid,
            name,
            devices: {},
            eventRound: 0
        }
        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${key}`), group);
        return new DBSuccess(true);
    }

    public async deleteDeviceGroup(id: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${id}`), null);
        return new DBSuccess(true);
    }

    public async addDeviceToGroup(deviceId: string, groupId: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${groupId}/devices/${deviceId}/enabled`), true);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/group`), groupId);
        return new DBSuccess(true);
    }

    public async removeDeviceFromGroup(deviceId: string, groupId: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${groupId}/devices/${deviceId}`), null);
        await set(ref(this.database, `${this.PREFIX}/devices/${deviceId}/group`), null);
        return new DBSuccess(true);
    }

    public async setDeviceStripIdx(deviceId: string, stripIdx: number): AsyncDBResult<boolean> {
        const deviceResult = await this.getDevice(deviceId);
        if (deviceResult.status === "fail") return deviceResult;
        const device = deviceResult.data;
        if (!device.group) return new DBError("This device is not in a group!");
        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${device.group}/devices/${deviceId}/stripIdx`), stripIdx);
        return new DBSuccess(true);
    }

    public async setGroupEvent(groupId: string, eventId: string, propagate: boolean): AsyncDBResult<boolean> {
        const groupResult = await this.getDeviceGroup(groupId);
        if (groupResult.status === "fail") return new DBError(ERROR_DEVICE_GROUP_DOES_NOT_EXIST);
        const group = groupResult.data;

        const dbUpdates = {
            [`/deviceGroups/${groupId}/event`]: eventId
        };

        if (propagate) {
            for (const deviceId in group.devices) {
                dbUpdates[`/devices/${deviceId}/event`] = eventId;
            }
        }

        await update(ref(this.database, `${this.PREFIX}`), dbUpdates);

        return new DBSuccess(true);
    }

    public async setGroupRoundIdx(groupId: string, roundIdx: number, propagate: boolean): AsyncDBResult<boolean> {
        const groupResult = await this.getDeviceGroup(groupId);
        if (groupResult.status === "fail") return new DBError(ERROR_DEVICE_GROUP_DOES_NOT_EXIST);
        const group = groupResult.data;
        if (!group.event) return new DBError(ERROR_EVENT_DOES_NOT_EXIST);

        await set(ref(this.database, `${this.PREFIX}/deviceGroups/${groupId}/eventRound`), roundIdx);

        if (propagate) {
            await this.applyGroupEventSettings(groupId);
        }

        return new DBSuccess(true);
    }

    public async applyGroupEventSettings(groupId: string): AsyncDBResult<boolean> {
        const groupResult = await this.getDeviceGroup(groupId);
        if (groupResult.status === "fail") return new DBError(ERROR_DEVICE_GROUP_DOES_NOT_EXIST);
        const group = groupResult.data;
        
        const dbUpdates: Record<string, unknown> = {};
        if (group.event) {
            const eventResult = await this.getEvent(group.event);
            if (eventResult.status === "fail") return new DBError(ERROR_EVENT_DOES_NOT_EXIST);
            const event = eventResult.data;

            for (const deviceId in group.devices) {
                const deviceResult = await this.getDevice(deviceId);
                if (deviceResult.status === "fail") return new DBError(ERROR_DEVICE_DOES_NOT_EXIST);
                const device = deviceResult.data;

                device.division = Division.NCAA;
                dbUpdates[`/devices/${deviceId}/event`] = event.id;
                dbUpdates[`/devices/${deviceId}/division`] = Division.NCAA;
                dbUpdates[`/devices/${deviceId}/roundIdx`] = group.eventRound;
                dbUpdates[`/devices/${deviceId}/meet`] = null;
                dbUpdates[`/devices/${deviceId}/bout`] = null;
                dbUpdates[`/devices/${deviceId}/boutIdx`] = 0;

                if (!device.eventGender || device.eventStrip === undefined) continue;

                const eventSchedule = event[device.eventGender === "mens" ? "mensRounds" : "womensRounds"];
                const eventMeet = eventSchedule[group.eventRound].meets[device.eventStrip];
                const meetResult = await this.getMeet(eventMeet.id, device.division);
                if (meetResult.status === "fail") return meetResult;
                const meet = meetResult.data;
                dbUpdates[`/devices/${deviceId}/meet`] = meet.id;

                if (!device.weapon) continue;
                const boutIdx = idxFromWeaponHs(device.weapon, 0);
                dbUpdates[`/devices/${deviceId}/bout`] = meet.bouts[boutIdx].id;
            }
        } else {
            for (const deviceId in group.devices) {
                dbUpdates[`/devices/${deviceId}/event`] = null;
                dbUpdates[`/devices/${deviceId}/roundIdx`] = group.eventRound;
                dbUpdates[`/devices/${deviceId}/meetIdx`] = null;
                dbUpdates[`/devices/${deviceId}/meet`] = null;
                dbUpdates[`/devices/${deviceId}/boutIdx`] = null;
                dbUpdates[`/devices/${deviceId}/bout`] = null;
            }
        }

        await update(ref(this.database, `${this.PREFIX}`), dbUpdates);

        return new DBSuccess(true);
    }

    // #endregion
    
    // #region Device messaging

    public async requestDeviceUploadLogs(deviceId: string | IDevice): AsyncDBResult<boolean> {
        try {
            let device = deviceId;
            if (typeof device === "string") {
                const deviceResult = await this.getDevice(device);
                if (deviceResult.status === "fail") return deviceResult;
                device = deviceResult.data;
            }
            const newBootOptions = (device.bootOptions || 0) | DeviceBootOptions.UploadLogs;
            await update(ref(this.database, `${this.PREFIX}/devices/${device.id}`), { bootOptions: newBootOptions });
            return new DBSuccess(true);
        } catch (e) {
            return new DBError((e as Error).message);
        }
    }

    public async cancelRequestDeviceUploadLogs(deviceId: string | IDevice): AsyncDBResult<boolean> {
        try {
            let device = deviceId;
            if (typeof device === "string") {
                const deviceResult = await this.getDevice(device);
                if (deviceResult.status === "fail") return deviceResult;
                device = deviceResult.data;
            }
            const newBootOptions = (device.bootOptions || 0) & ~DeviceBootOptions.UploadLogs;
            await update(ref(this.database, `${this.PREFIX}/devices/${device.id}`), { bootOptions: newBootOptions });
            return new DBSuccess(true);
        } catch (e) {
            return new DBError((e as Error).message);
        }
    }

    public async requestDeviceUpdateFirmware(deviceId: string | IDevice, version: string): AsyncDBResult<boolean> {
        try {
            let device = deviceId;
            if (typeof device === "string") {
                const deviceResult = await this.getDevice(device);
                if (deviceResult.status === "fail") return deviceResult;
                device = deviceResult.data;
            }
            const newBootOptions = (device.bootOptions || 0) | DeviceBootOptions.UpdateFirmware;
            await update(ref(this.database, `${this.PREFIX}/devices/${device.id}`), { bootOptions: newBootOptions, bootVersion: version });
            return new DBSuccess(true);
        } catch (e) {
            return new DBError((e as Error).message);
        }
    }

    public async cancelRequestDeviceUpdateFirmware(deviceId: string | IDevice): AsyncDBResult<boolean> {
        try {
            let device = deviceId;
            if (typeof device === "string") {
                const deviceResult = await this.getDevice(device);
                if (deviceResult.status === "fail") return deviceResult;
                device = deviceResult.data;
            }
            const newBootOptions = (device.bootOptions || 0) & ~DeviceBootOptions.UpdateFirmware;
            await update(ref(this.database, `${this.PREFIX}/devices/${device.id}`), { bootOptions: newBootOptions, bootVersion: null });
            return new DBSuccess(true);
        } catch (e) {
            return new DBError((e as Error).message);
        }
    }

    // #endregion

    // #region Device invites

    public async inviteAdmin(deviceIds: string[]): AsyncDBResult<boolean> {
        const userRequest = await this.getCurrentUserInfo();
        if (userRequest.status === "fail") return userRequest;
        const user = userRequest.data;
        const newInviteRef = await push(ref(this.database, `${this.PREFIX}/invites/`));
        const code = genRandomStr().slice(0, 6).toUpperCase();
        const invite: IDeviceInvite = {
            id: newInviteRef.key!,
            type: "device",
            devices: Object.fromEntries(deviceIds.map(l => [l, true])),
            code,
            createdAt: Date.now(),
            createdBy: user.id,
            expiresAt: Date.now() + 1000 * 60 * 60 * 24,
            claimed: false
        };

        await set(newInviteRef, invite);
        return new DBSuccess(true);
    }

    public async deleteInvite(id: string): AsyncDBResult<boolean> {
        await set(ref(this.database, `${this.PREFIX}/invites/${id}`), null);
        return new DBSuccess(true);
    }

    public async joinTeamUsingInvite(code: string): AsyncDBResult<boolean> {
        const user = await this.getCurrentUserInfo();
        if (user.status === "fail") return new DBError(ERROR_NOT_LOGGED_IN);

        const matchingInvite = await this.getInviteFromCode(code);
        if (matchingInvite.status === "fail") return matchingInvite;
        if (matchingInvite.data.claimed) return new DBError(ERROR_INVITE_CLAIMED);

        await update(ref(this.database, `${this.PREFIX}/invites/${matchingInvite.data.id}`), {
            "/claimed": true,
            "/claimedAt": Date.now()
        });

        const updates: Record<string, unknown> = {};
        for (const device in matchingInvite.data.devices) {
            updates[`${this.PREFIX}/devices/${device}/owners/${user.data.id}`] = true;
        }
        await update(ref(this.database), updates);
        return new DBSuccess(true);
    }

    public async getInviteFromCode(code: string): AsyncDBResult<IDeviceInvite> {
        const v = await get(query(ref(this.database, `${this.PREFIX}/invites`), orderByChild("code"), equalTo(code.toUpperCase())));
        if (!v.val()) return new DBError(ERROR_INVALID_INVITE);
        const invite = Object.values(v.val())[0] as IDeviceInvite;
        if (!invite) return new DBError(ERROR_INVALID_INVITE);
        return new DBSuccess(invite);
    }

    public async getInvite(id: string, listener?: (v: IDeviceInvite | null) => void): Promise<IDeviceInvite | null> {
        if (listener) {
            return new Promise(res => {
                onValue(ref(this.database, `${this.PREFIX}/invites/${id}`), v => {
                    const val = v.val();
                    listener(val);
                    res(val);
                });
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/invites/${id}`));
            return v.val();
        }
    }

    public async getInvitesByUser(
        userId: ID,
        listener?: (v: DBResult<Record<string, IDeviceInvite>>) => void
    ): AsyncDBResult<Record<string, IDeviceInvite>> {
        if (listener) {
            return new Promise(res => {
                onValue(query(ref(this.database, `${this.PREFIX}/invites`), orderByChild("createdBy"), equalTo(userId)), v => {
                    const val = v.val() || {};
                    const resp = new DBSuccess(val);
                    listener(resp);
                    res(resp);
                });
            });
        } else {
            const v = await get(query(ref(this.database, `${this.PREFIX}/invites`), orderByChild("createdBy"), equalTo(userId)));
            return new DBSuccess(v.val() || {});
        }
    }

    public async removeUserFromDevices(userId: ID, devices: string[]) {
        const databaseUpdates: Record<string, unknown> = {};
        for (const device of devices) {
            databaseUpdates[`/devices/${device}/owners/${userId}`] = null;
        }
        await update(ref(this.database, this.PREFIX), databaseUpdates);
        return new DBSuccess(true);
    }

    // #endregion

    // #region Firmware versions

    public async getFirmwareVersions(listener?: Listener<DBResult<string[]>>): AsyncDBResult<string[]> {
        if (listener) {
            return new Promise(async (res) => {
                const l = v => {
                    const val: string[] = v.val();
                    const response = val ? new DBSuccess(val) : new DBError("Firmware versions could not be found.");
                    listener(response);
                    res(response);
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/firmwareVersions`), l);
                this.unsubscribers.set(listener, unsubscriber);
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/firmwareVersions`));
            const versions = v.val() as string[];
            return new DBSuccess(versions);
        }
    }

    public stopListeningFirmwareVersions = (listener: Listener<DBResult<string[]>>) => {
        const l = this.unsubscribers.get(listener);
        if (l) {
            l();
            this.unsubscribers.delete(l);
        }
    }

    // #endregion

    // Scoring

    public async getBout(id: string, division: Division, listener?: Listener<DBResult<IDualMeetBout>>): AsyncDBResult<IDualMeetBout> {
        if (listener) {
            return new Promise(async (res, rej) => {
                const l = v => {
                    const val: IDualMeetBout | null = v.val();
                    const response = val ? new DBSuccess(val) : new DBError(ERROR_BOUT_DOES_NOT_EXIST);
                    listener(response);
                    res(response);
                };
                const unsubscriber = onValue(ref(this.database, `${this.divToPrefix(division)}/bouts/${id}`), l);
                if (this.deviceUnsubscribers.has(listener)) {
                    this.deviceUnsubscribers.get(listener)![id] = unsubscriber;
                } else {
                    this.deviceUnsubscribers.set(listener, { [id]: unsubscriber });
                }
            });
        } else {
            const v = await get(ref(this.database, `${this.divToPrefix(division)}/bouts/${id}`));
            const val = v.val();
            if (!val) return new DBError(ERROR_BOUT_DOES_NOT_EXIST);
            return new DBSuccess(val);
        }
    }

    public stopListeningBout = (id: string, listener: Listener<DBResult<IDualMeetBout>>) => {
        const l = this.deviceUnsubscribers.get(listener);
        if (l) {
            l[id]();
            delete l[id];
        }
    }

    public async getMeet(id: string, division: Division): AsyncDBResult<IDualMeet_DB> {
        const v = await get(ref(this.database, `${this.divToPrefix(division)}/dualMeets/${id}`));
        const val = v.val();
        if (!val) return new DBError(ERROR_DUAL_MEET_DOES_NOT_EXIST);
        return new DBSuccess(val);
    }

    public async getMeets(division: Division): AsyncDBResult<Record<string, IDualMeet_DB>> {
        const v = await get(ref(this.database, `${this.divToPrefix(division)}/dualMeets`));
        const val = v.val() || {};
        return new DBSuccess(val);
    }

    public async getEvent(id: string): AsyncDBResult<ICollegeEvent> {
        const v = await get(ref(this.database, `${this.COLLEGE_PREFIX}/events/${id}`));
        const val = v.val();
        if (!val) return new DBError(ERROR_EVENT_DOES_NOT_EXIST);
        return new DBSuccess(val);
    }

    public async getEvents(): AsyncDBResult<Record<string, ICollegeEvent>> {
        const v = await get(ref(this.database, `${this.COLLEGE_PREFIX}/events`));
        const val = v.val();
        if (!val) return new DBError(ERROR_EVENT_DOES_NOT_EXIST);
        return new DBSuccess(val);
    }
}